import { createComboKeys } from "@chatbotgang/etude/event/createComboKeys";

export function registerComboKeys(fn: () => void): void {
  createComboKeys({
    combo: [
      "ArrowUp",
      "ArrowUp",
      "ArrowDown",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "ArrowLeft",
      "ArrowRight",
      "b",
      "a",
    ],
    handler: fn,
    timeoutDuration: 1000,
  });
}
