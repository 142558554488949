import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import type { ComponentProps, ReactNode } from "react";
import { useMemo } from "react";

const cssDistList = {
  self: css({
    paddingInlineStart: "1.57em",
  }),
  ">li": css({
    listStyleType: "disc",
  }),
};

type DiscListProps = ComponentProps<"ul"> &
  (
    | {
        items: Array<ReactNode>;
      }
    | {
        /**
         * split by `\n` and render as list items
         */
        items: string;
      }
  );

export const DiscList = memo<DiscListProps>(function DiscList(props) {
  const items = useMemo<Array<ReactNode>>(
    function computeItems() {
      if (Array.isArray(props.items)) return props.items;

      const listItmes = props.items.split("\n").flatMap<string>((line) => {
        const trimmedLine = line.trim();
        if (!trimmedLine) return [];
        return [trimmedLine];
      });

      return listItmes;
    },
    [props.items],
  );

  return (
    <ul css={cssDistList.self} {...props}>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
});
